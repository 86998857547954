import { useSelector, useDispatch } from "react-redux";
import { setUser, initialState } from "store/auth/userSlice";
import { apiSignIn, apiSignOut, apiSignUp } from "services/AuthService";
import { onSignInSuccess, onSignOutSuccess } from "store/auth/sessionSlice";
import appConfig from "configs/app.config";
import {
  REDIRECT_URL_KEY,
  USER_ENCRYPTION_KEY,
  USER_SESSION_ENCRYPTION_KEY,
} from "constants/app.constant";
import { useNavigate } from "react-router-dom";
import useQuery from "./useQuery";
import { clearAllCart } from "store/invice/cartSlice";
import { removeBillDiscount } from "store/invice/billDiscount";
import { erasecashData } from "store/invice/cashSlice";
import { eraseAddress } from "store/invice/deliveryAddress";
import { removeCustomerDetails } from "store/invice/dataSlice";
import { addCredit, erasecreditData } from "store/invice/credit_slice";
import { removehold_bill_count, removeorder_id } from "store/invice/orderSlice";
import { encryptData } from "utilities/Store_data";
import { setUser_dummy } from "store/auth/user_dummySlice";
import { pos_login_api } from "services/invice";

function useAuth() {
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const query = useQuery();

  const { token, signedIn } = useSelector((state) => state.auth.session);

  const signIn = async (values) => {
    try {
      const resp = await pos_login_api({
        pos_udaan: {
          sales_executive_id: values.id,
          password: values.password,
        },
      });

      if (resp.data.body.status != 0) {
        const res_data = resp.data.body;

        // const { token } = resp.data
        const token_data = encryptData(
          USER_SESSION_ENCRYPTION_KEY,
          "ghfghffjjghjhjg"
        );
        dispatch(onSignInSuccess(token_data));
        // if(resp.data.user) {
        const assigned_device = res_data?.pgateways?.filter((item) => {
          return item?.assigned_user == res_data?.userid;
        });
        const user_data = encryptData(
          USER_ENCRYPTION_KEY,
          JSON.stringify({
            ...res_data,
            authority: res_data.role,
            outlet_id: res_data.clients_id,
            exec_id: values.id,
            assigned_device: assigned_device,
          })
        );

        //user_type => 40 = executive, 41 = admin

        dispatch(setUser(user_data));
        dispatch(clearAllCart());
        dispatch(
          removeBillDiscount({
            subtotal: 0,
            total: 0,
            discount: 0,
            discountType: true,
          })
        );
        dispatch(erasecashData());
        dispatch(eraseAddress());
        dispatch(removeCustomerDetails());
        dispatch(addCredit(0));
        dispatch(removehold_bill_count());
        dispatch(removeorder_id());
        // }
        const redirectUrl = query.get(REDIRECT_URL_KEY)
        navigate(redirectUrl ? redirectUrl : appConfig.authenticatedEntryPath)
        return {
        	status: 'success',
        	message: ''
        }
      } else {
        return {
          status: "failed",
          message: resp.data.body.msg,
        };
      }
    } catch (errors) {
      return {
        status: "failed",
        message: errors?.response?.data?.message || errors.toString(),
      };
    }
  };

  const signUp = async (values) => {
    try {
      const resp = await apiSignUp(values);
      if (resp.data) {
        const { token } = resp.data;
        const token_data = encryptData(USER_SESSION_ENCRYPTION_KEY, token);
        dispatch(onSignInSuccess(token_data));
        if (resp.data.user) {
          const user_data = encryptData(
            USER_ENCRYPTION_KEY,
            JSON.stringify(
              resp.data.user || {
                avatar: "",
                userName: "Anonymous",
                authority: ["USER"],
                email: "",
              }
            )
          );
          dispatch(setUser(user_data));
        }
        const redirectUrl = query.get(REDIRECT_URL_KEY);
        navigate(redirectUrl ? redirectUrl : appConfig.authenticatedEntryPath);
        return {
          status: "success",
          message: "",
        };
      }
    } catch (errors) {
      return {
        status: "failed",
        message: errors?.response?.data?.message || errors.toString(),
      };
    }
  };

  const handleSignOut = () => {
    dispatch(onSignOutSuccess());
    const user_data = encryptData(
      USER_ENCRYPTION_KEY,
      JSON.stringify(initialState)
    );
    dispatch(setUser(user_data));
    navigate(appConfig.unAuthenticatedEntryPath);
  };

  const signOut = async () => {
    // await apiSignOut()
    handleSignOut();
  };

  return {
    authenticated: token && signedIn,
    signIn,
    signUp,
    signOut,
  };
}

export default useAuth;
