export const APP_NAME = 'Udaan'
export const PERSIST_STORE_NAME = 'admin'
export const REDIRECT_URL_KEY = 'redirectUrl'
export const USER_ENCRYPTION_KEY = 'jsdyt43htjpou'
export const USER_SESSION_ENCRYPTION_KEY = 'dfy7s4hty75dr'
export const PineLabClientID = '1002209'
export const PineLabStoreID = '1221258'
export const PineLabMerchantID = '29610'
export const PineLabSecurityToken = 'a4c9741b-2889-47b8-be2f-ba42081a246e'


